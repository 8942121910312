import React, { useEffect } from "react";

type PurchaseTrackingProps = {
  orderId: string | number;
  orderSubtotal: string | number;
};
const PurchaseTracking = ({
  orderId,
  orderSubtotal,
}: PurchaseTrackingProps) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.dwin1.com/81353.js";
    script.type = "text/javascript";
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const trackingImage = `
    <img 
      src="https://www.shareasale.com/sale.cfm?tracking=${orderId}&amount=${orderSubtotal}&merchantID=155440&transtype=sale" 
      width="1" 
      height="1" 
    />
  `;

  return <div dangerouslySetInnerHTML={{ __html: trackingImage }} />;
};

export default PurchaseTracking;
