import { RadioGroup } from "@headlessui/react";
import Icon from "@/components/Icon";
import { useTranslation } from "react-i18next";

type RadioType = {
  id: string;
  title: any;
  value: number;
  save?: number;
};

type RadioProps = {
  items: RadioType[];
  value: any;
  setValue: any;
  title: any;
  save: any;
  selectedPlanData?: any;
  textWhite?: any;
};

const Radio = ({
  items,
  value,
  setValue,
  title,
  save,
  selectedPlanData,
  textWhite,
}: RadioProps) => {
  const { t, i18n } = useTranslation("translation");

  console.log("selectedPlanData", selectedPlanData);
  console.log("value", value);
  const getPlanById = (id: any, plansArray: any) =>
    plansArray?.find((plan: any) => plan._id === id) || null;
  const plansArray = selectedPlanData?.package_plan;
  const selectedPlan = getPlanById(selectedPlanData.id, plansArray);
  setValue(selectedPlan);

  return (
    <RadioGroup
      className="mb-6 flex md:flex-col flex-wrap flex-row gap-3"
      value={value} // Use the 'id' property for the selected plan
      onChange={setValue}
      name="plan"
    >
      {items?.map((item) =>{
          const planTitle= item.title==="Pro"?t("pro_plan_title"):item.title==="Premium"?t("premium_plan_title"):t("pay_plan_title")
        return  (
        <RadioGroup.Option
          key={item.id}
          value={item}
          className="p-3.5 whitespace-nowrap 2xl:w-full bg-n-2 border-2 border-n-2 rounded-xl transition-colors ui-checked:border-primary-1 ui-checked:bg-transparent cursor-pointer tap-highlight-color  dark:border-transparent dark:ui-checked:border-primary-1"
        >
          <div className="flex mb-1">
            <div className={`base2 ${textWhite ? "text-white" : "text-black"}`}>
              {t("pay")} {planTitle}
            </div>
            <Icon
              className="ml-auto fill-primary-1 opacity-0 transition-opacity ui-checked:opacity-100"
              name="check-thin"
            />
          </div>
          <div className="flex items-center gap-4">
            <div
              className={`base1 font-semibold ${textWhite ? "text-white" : "text-black"
                }`}
            >
              {/* @ts-ignore */}
              {item?.price}$/
              {title.toLowerCase().includes("monthly")
                ? "mo"
                : title.toLowerCase().includes("semester")
                  ? "se"
                  : "ye"}
            </div>
            {item?.title.toLowerCase().includes("premium") && (
              <div className="ml-auto px-2 base2 bg-primary-2/15 rounded text-[#0C923C] whitespace-nowrap">
                {t("save_money")} {save}%
              </div>
            )}

          </div>
        </RadioGroup.Option>
      )} 
    )}
    </RadioGroup>
  );
};

export default Radio;
