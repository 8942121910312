import Link from "next/link";
import Icon from "@/components/Icon";
import { ImSpinner9 } from "react-icons/im";
import Field from "@/components/Field";
import { useEffect, useState } from "react";
import { size } from "lodash";
import { planService } from "services/plan.service";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

type FootProps = {
  handleCard: (event: React.FormEvent) => void;
  plan: any;
  loading?: any;
  setReferralCode?: any;
  setIsverified?: any;
  isVerified?: any;
  isFullFree?: any;
  setisFullFree?: any;
  fiftyPercentOff?: any;
};

const Foot = ({
  handleCard,
  plan,
  loading,
  setReferralCode,
  setIsverified,
  isVerified,
  isFullFree,
  setisFullFree,
  fiftyPercentOff,
}: FootProps) => {
  const [show, setShow] = useState(false);

  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [refLoading, setrefLoading] = useState(false);
  const isSpecialCountry = useSelector(
    (state: any) => state.planStore.isSpecialCountry?.data
  );
  const { t } = useTranslation("translation");
  const userData = useSelector((state: any) => state.authStore);

  const { user } = userData;
  const currentPlan = user?.data?.planData || user?.data?.plan;

  const currentPlanPrice = currentPlan?.price;
  const handleApplyButtonClick = (
    code: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    handleApplyCode(code);
  };

  const handleApplyCode = (code: any) => {
    setrefLoading(true);
    planService
      .verifyReferralCode({ referralCode: code, plan })
      .then((response) => {
        if (response?.data?.data?.isVerified) {
          setIsverified(response.data.data.isVerified);
          setisFullFree(response.data.data.isFullFree == true ? true : false);
          setError("");
          setReferralCode(code);
          setShow(false);
        } else {
          setShow(true);
          setIsverified(false);
          setisFullFree(false);
          setError(t("invalid_referralCode"));
        }
        setrefLoading(false);
      })
      .catch((error) => {
        console.error(error, "Error occurred");
        // Handle error here
        setrefLoading(false);
      });
  };
  const customPlan = useSelector(
    (state: any) => state.planStore.customPlanData
  );

  const discountedPrice =
    fiftyPercentOff == true && currentPlanPrice === 0
      ? plan?.price * 0.5
      : isVerified && isFullFree && customPlan?.currentPlan === false
      ? plan?.price * 0.0
      : isVerified
      ? plan?.price * 0.75
      : plan?.price;
  console.log("discountedPrice", discountedPrice);

  const subtractPrices = (
    planPrice: number,
    currentPlanPrice: number
  ): number => {
    return planPrice - currentPlanPrice;
  };
  useEffect(() => {
    if (code?.length < 1) {
      setError("");
    }
  }, [code]);
  const planTitle= plan.title==="Pro"?t("pro_plan_title"):plan.title==="Premium"?t("premium_plan_title"):t("pay_plan_title")
  return (
    <div className="">
      <div className="flex items-center mb-6 caption1 text-n-4/50 dark:text-n-4">
        <Icon className="w-4 h-4 mr-2 fill-[#0C923C]" name="lock" />
        {t("secured_stripe")}
      </div>

      {/* <div className="pb-6">
        <span className="text-sm font-medium !font-mont text-black mb-2 block dark:text-white ">
          {t("doHave_referralCode")}
        </span>
        {show ? (
          <div className="relative">
            <Field
              value={code}
              onChange={(e: any) => setCode(e.target.value)}
              placeholder={
                fiftyPercentOff
                  ? t("fifty_discount_applied")
                  : t("enter_referralCode")
              }
              classInput="!bg-white dark:!bg-transparent dark:!border-n-2 dark:!text-white"
              disabled={fiftyPercentOff}
            />

            <button
              type="button"
              disabled={!code || refLoading}
              className="btn-blue absolute top-1/2 -translate-y-1/2 right-0 rounded-r-md rounded-none"
              onClick={(e) => handleApplyButtonClick(code, e)}
            >
              {refLoading == true ? (
                <ImSpinner9 className="animate-spin" />
              ) : (
                t("apply")
              )}
            </button>
          </div>
        ) : (
          <div className="w-full p-3 flex justify-between items-center bg-n-3">
            <div className="flex gap-3 items-center">
              <span className="h-9 w-9 bg-primary-1 flex justify-center items-center rounded-full !font-mont">
                <Icon name="check" className="fill-white" />
              </span>
              <div>
                <p className="text-black text-sm font-semibold !font-mont">
                  {" "}
                  {code} <span className="font-light"> {t("applied")}</span>
                </p>
                <span className="block text-sm  text-black !font-mont">
                  {`${isVerified && isFullFree ? 100 : 25}% ${t("off")}`}
                </span>
              </div>
            </div>

            <button
              className="text-black font-semibold"
              onClick={() => {
                setShow(true);
                setCode("");
                setIsverified(false);
                setisFullFree(false);
              }}
            >
              {t("remove")}
            </button>
          </div>
        )}
        {error && <div className="text-red-500 mt-2">{error}</div>}
      </div> */}
      <div className="text-right">
        {/* {currentPlanPrice === 0 || customPlan?.currentPlan === false ? (
          <>
            {isSpecialCountry ? (
              <div className="h4 text-black dark:text-white">
                {t("billed_now")}: {discountedPrice * 5.74}(R$)
              </div>
            ) : (
              <div className="h4 text-black dark:text-white">
                {t("billed_now")}: {discountedPrice}$
              </div>
            )}
          </>
        ) : (
          <>
            {isSpecialCountry ? (
              <div className="h4 text-black dark:text-white">
                {t("billed_now")}:{" "}
                {currentPlan?.title === "Custom" && plan?.title == "Custom" ? (
                  <>{discountedPrice} * 5.74 (R$)</>
                ) : (
                  <>
                    {subtractPrices(
                      discountedPrice * 5.74,
                      currentPlanPrice * 5.74
                    )}
                    (R$)
                  </>
                )}
              </div>
            ) : (
              <div className="h4 text-black dark:text-white">
                {t("billed_now")}:{" "}
                {currentPlan?.title === "Custom" && plan?.title == "Custom" ? (
                  <>{discountedPrice} $</>
                ) : (
                  <>{subtractPrices(discountedPrice, currentPlanPrice)}$</>
                )}
              </div>
            )}
          </>
        )} */}
        <>
          {isSpecialCountry ? (
            <div className="h4 text-black dark:text-white">
              {t("billed_now")}:{" "}
              {currentPlan?.title === "Custom" && plan?.title == "Custom" ? (
                // <>{discountedPrice} * 5.74 (R$)</>
                <> {plan?.price}(R$)</>
              ) : (
                // <>
                //   {subtractPrices(
                //     discountedPrice * 5.74,
                //     currentPlanPrice * 5.74
                //   )}
                //   (R$)
                // </>
                <> {plan?.price}(R$)</>
              )}
            </div>
          ) : (
            <div className="h4 text-black dark:text-white">
              {t("billed_now")}:
              {currentPlan?.title === "Custom" && plan?.title == "Custom" ? (
                // <>{discountedPrice} $</>
                <> {plan?.price}$</>
              ) : (
                <> {plan?.price}$</>
                // <>{subtractPrices(discountedPrice, currentPlanPrice)}$</>
              )}
            </div>
          )}
        </>

        {/* <button
                className="mb-4 base2 font-semibold text-primary-1 transition-colors hover:text-primary-1/90"
                type="button"
            >
                Apply promo code
            </button> */}

        {currentPlanPrice === 0 || customPlan?.currentPlan === false ? (
          <div className="max-w-[27rem] ml-auto mb-4 caption1 text-n-4/50 dark:text-n-4">
           {t("price_desc1")} {planTitle} {t("price_desc2")}{" "}
            {isSpecialCountry ? (
              <>{plan?.price * 5.74}(R$)</>
            ) : (
              <>{plan?.price}$</>
            )}{" "}
            {t("every")}{" "}
            {plan?.priceDetails.includes("yearly")
              ? "year"
              : plan?.priceDetails.includes("monthly")
              ? "month"
              : "semester"}
            {t("price_desc3")}
          </div>
        ) : (
          <div className="max-w-[27rem] ml-auto mb-4 caption1 text-n-4/50 dark:text-n-4">
              {t("price_desc1")} {planTitle} {t("price_desc2")} {plan?.price}${" "}
            {t("every")}{" "}
            {plan?.priceDetails?.includes("yearly")
              ? "year"
              : plan?.priceDetails?.includes("monthly")
              ? "month"
              : "semester"}
            {t("price_desc3")}
          </div>
        )}

        <button
          onClick={handleCard}
          className="btn-blue md:w-full whitespace-nowrap"
          type="button"
        >
          {loading === true ? (
            <ImSpinner9 className="animate-spin" />
          ) : (
            t("buy")
            // <>
            //   {currentPlanPrice === 0 || customPlan?.currentPlan === false
            //     ? `${t("start_btn")} ${plan?.title} ${"plan"}`
            //     : `${t("upgrade_plan")}`}
            // </>
          )}
        </button>
      </div>
    </div>
  );
};

export default Foot;
