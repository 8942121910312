import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import Modal from "@/components/Modal";
import TrialPeriodFeatures from "@/components/TrialPeriodFeatures";
import { useDispatch, useSelector } from "react-redux";
import { addPlanPrice } from "store/slices/PlanSlice";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import { useTranslation } from "react-i18next";
import { planService } from "services/plan.service";
import { setUserSubscription } from "store/slices/AuthSlice";
import toast from "react-hot-toast";
import Notify from "@/components/Notify";
import ChangeSubscriptionModal from "@/components/Modal/ChangeSupscriptionModal";

type PackageProps = {
  plan?: any;
  item: any;
  package_plan: any;
  currentPlan?: any;
  currentPack?: any;
  featuresPrice?: any;
};

const Package = ({
  plan,
  item,
  package_plan,
  currentPlan,
  featuresPrice,
}: PackageProps) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t, i18n } = useTranslation("translation");
  const userData = useSelector((state: any) => state.authStore);
  const { user } = userData;

  const dispatchItemToRedux = (id: any) => {
    dispatch(addPlanPrice({ package_plan, id }));
  };

  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleUpdateSubModal, setvisibleUpdateSubModal] =
    useState<boolean>(false);
  const [updateLoading, setupdateLoading] = useState<boolean>(false);
  const [currentPack, setCurrentPack] = useState<boolean>(false);

  useEffect(() => {
    if (item?._id === user?.data?.plan) {
      setCurrentPack(item._id);
    } else {
      setCurrentPack(user?.data?.similarPlans.includes(item._id));
    }
  }, [plan]);

  const upgradePlan = async (planid: any, title: string) => {
    setupdateLoading(true);
    planService
      .updateSubscrition({ planId: planid, isVerified: true })
      .then((user: any) => {
        const updatedUser = {
          ...userData.user.data,
          subscription: user.data.data.subscription,
          planData: item,
          plan: planid,
        };
        dispatch(setUserSubscription(updatedUser));

        toast(
          (t) => (
            <Notify iconCheck>
              <div className="h6 sm:text-sm">{"Subscription Updated"}</div>
            </Notify>
          ),
          { duration: 3000 }
        );
        // if (userData?.user?.data?.userName) {
        router.push({
          pathname: ROUTES.THANKS,
          query: { plan: title },
        });
        // }
        // else {
        //   window.location.replace(`loki://lokichat.com?subscription=${user.data.data.subscription}`);
        // }
      })
      .catch((error: any) => {
        setupdateLoading(false);
        console.log(error);
        // setLoading(false);
        if (error.response?.data?.status === 401) {
          window.location.href = ROUTES.SIGNIN;
        } else {
          toast(
            <Notify>
              <div className="ml-3 h6 sm:text-sm">
                {error.response?.data?.message ??
                  "Error while updating subscription"}
              </div>
            </Notify>
          );
        }
      });
  };
  const planTitle =
    item.title === "Pro"
      ? t("pro_plan_title")
      : item.title === "Premium"
      ? t("premium_plan_title")
      : t("pay_plan_title");
  const priceDescription =
    item.title === "Pro"
      ? t("pro_plan_priceDescription")
      : item.title === "Premium"
      ? t("premium_plan_priceDescription")
      : t("pay_plan_priceDescription");
  const priceDetails =
    item.title === "Pro"
      ? t("pro_plan_priceDetails")
      : item.title === "Premium"
      ? t("premium_plan_priceDetails")
      : t("pay_plan_priceDetails");
  const description =
    item.title === "Pro"
      ? t("pro_plan_description")
      : t("premium_plan_description");
  return (
    <div
      className={`flex basis-1/3 border-r-2 border-n-3 p-[32px] bg-n-1 first:rounded-l-3xl  last:rounded-r-3xl last:border-none 2xl:px-6 lg:shrink-0 lg:basis-[18.5rem] 4xl:shrink-0 h-[323px] 4xl:px-6 dark:bg-[#282828B2] dark:border-n-6 shadow-[inset_2px_4px_16px_0_rgba(248,248,248,0.06)] 4xl:p-[20px] ${
        item.popular &&
        "relative text-n-1 before:absolute before:-top-4 before:left-0 before:right-0 before:-bottom-4 before:bg-n-6 before:rounded-3xl  dark:before:bg-[#0084FF] w-[334px]"
      }`}
    >
      <div className="relative flex flex-col grow z-2">
        <div className="flex items-center mb-1 grow">
          <div
            className={` text-[22px] font-extrabold text-n-4
              ${item.popular && "text-[#ffffff]"} 
               ${
                 router.pathname === ROUTES.PRICINGHOME ? "font-mont" : ""
               } h4 ${item.popular && "text-white"}
                 ${
                   router.pathname !== ROUTES.PRICING &&
                   !item.popular &&
                   "dark:text-[rgba(248,248,248,0.50)]"
                 }`}
            style={{ color: item.colorTitle }}
          >
            {planTitle}
          </div>
          {item.popular && (
            <div
              className={`${
                router.pathname === ROUTES.PRICINGHOME ? "font-mont" : ""
              } shrink-0 ml-2 px-3 py-0.5 bg-[#CFE8FF] rounded caption1 font-extrabold text-n-7 text-[12px] 
                `}
            >
              {t("popular")}
            </div>
          )}
        </div>
        {/* <div
          className={`${
            router.pathname === ROUTES.PRICINGHOME ? "font-mont" : ""
          } mb-6 base1 font-extrabold grow`}
        >
          {t(`${item.description}`)}
        </div> */}
        <div className={` text-n-4  ${item.popular && "text-[#ffffff]"} `}>
          <span
            className={`${
              router.pathname === ROUTES.PRICINGHOME ? "font-mont" : ""
            } mr-2 h2 text-[35px] ${item.popular && "text-white"}
             ${
               router.pathname !== ROUTES.PRICING &&
               !item.popular &&
               "dark:text-[rgba(248,248,248,0.50)]"
             }
            `}
          >
            ${item.price}
          </span>

          <span
            className={`font-bold text-[14px] ${item.popular && "text-white"}
               ${
                 router.pathname !== ROUTES.PRICING &&
                 !item.popular &&
                 "dark:text-[rgba(248,248,248,0.50)]"
               }`}
          >
            {description}
          </span>
          <div
            className={`${
              router.pathname === ROUTES.PRICINGHOME ? "font-mont" : ""
            }  text-[14px] font-extrabold grow ${item.popular && "text-white"}
               ${
                 router.pathname !== ROUTES.PRICING &&
                 !item.popular &&
                 "dark:text-[rgba(248,248,248,0.50)]"
               }`}
          >
            {priceDetails}
          </div>
        </div>
        <div
          className={`${
            router.pathname === ROUTES.PRICINGHOME ? "font-mont" : ""
          } font-[600] text-[16px] mt-[24px] text-n-4 ${
            item.popular && "text-white"
          }
             ${
               router.pathname !== ROUTES.PRICING &&
               !item.popular &&
               "dark:text-[rgba(248,248,248,0.50)]"
             }`}
        >
          {" "}
          {priceDescription}
        </div>
        <div className={`grow border-n-3 dark:border-n-6`}></div>
        {router.pathname === ROUTES.PRICINGHOME ||
        router.pathname === "/document-translator" ||
        router.pathname === ROUTES.HOME ? (
          <button
            //onClick={() => router.push(ROUTES.SIGNIN)}
            // onClick={() => {
            //   setVisibleModal(true);
            //   dispatchItemToRedux(item._id);
            // }}
            className={` cursor-pointer ${
              router.pathname === ROUTES.PRICINGHOME ? "font-mont" : ""
            } ${
              item.popular ? "btn-popular" : "subscribe-now-btn"
            } cursor-default
              ${router.pathname !== ROUTES.PRICING && !item.popular && ""}`}
          >
            {t("coming_soon")}
          </button>
        ) : (
          <>
            {user?.data?.fullPlanData?.platform == "web" && (
              <button
                className={`${
                  item._id === user?.data?.plan &&
                  "opacity-50 pointer-events-none"
                } ${
                  item.popular
                    ? "btn-popular 4xl:p-[20px]"
                    : "btn-stroke-light subscribe-now-btn"
                }  ${
                  router.pathname !== ROUTES.PRICING &&
                  !item.popular &&
                  "dark:text-[rgba(248,248,248,0.50)]"
                } w-full mt-6
                 `}
                onClick={() => {
                  if (!user?.data?.subscription) {
                    setVisibleModal(true);
                    dispatchItemToRedux(item._id); // Dispatch the item to Redux when the button is clicked
                  } else if (currentPlan.price > item.price) {
                    // upgradePlan(item._id)
                    setvisibleUpdateSubModal(true);
                  } else if (currentPlan.price < item.price) {
                    setVisibleModal(true);
                    dispatchItemToRedux(item._id);
                  }
                }}
              >
                {currentPack
                  ? t("current")
                  : currentPlan && currentPlan.price > item.price
                  ? t("downgrade")
                  : t("upgrade")}
              </button>
            )}
          </>
        )}
      </div>
      <Modal
        noicon
        className="md:!p-0"
        classWrap="max-w-[48rem] md:min-h-screen-ios md:rounded-none md:pb-8"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <TrialPeriodFeatures
          item={item}
          onCancel={() => setVisibleModal(false)}
          featuresList={featuresPrice}
          title={planTitle}
          onNext={() => dispatchItemToRedux(item._id)}
        />
      </Modal>
      <ChangeSubscriptionModal
        loading={updateLoading}
        onClose={() => {
          setvisibleUpdateSubModal(false);
        }}
        visible={visibleUpdateSubModal}
        changeSubscription={() => {
          upgradePlan(item._id, item.title);
        }}
      />
    </div>
  );
};

export default Package;
