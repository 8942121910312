import React, { useEffect, useState } from "react";
import Icon from "@/components/Icon";
import RadioGroup from "../Form/RadioGroup";
import { RadioGroup as FormRadioGroup } from "@headlessui/react";

import Foot from "../Form/Foot";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { planService } from "services/plan.service";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { setUserSubscription } from "store/slices/AuthSlice";
import { ROUTES } from "@/constants/routes";
import { useTranslation } from "react-i18next";
import PurchaseTracking from "@/components/PurchaseTracking";
import { useColorMode } from "@chakra-ui/color-mode";
import { useRouter } from "next/router";
import { setFiftyPercentOff } from "store/slices/AuthSlice";
import AdditionalFoot from "../AdditionalForm/AdditionalFoot";
type FormProps = {
  addonValue?: any;
  currentPlan?: any;
  selectedPlanData: any;
  setVisibleModal: any;
};

const AdditionalForm = ({
  addonValue,
  currentPlan,
  selectedPlanData,
  setVisibleModal,
}: FormProps) => {
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation(["auth", "translation"]);
  const { t } = useTranslation("translation");
  const router = useRouter();
  const userData = useSelector((state: any) => state.authStore);
  //   const customPlan = useSelector(
  //     (state: any) => state.planStore.customPlanData
  //   );
  // console.log("customPlancustomPlan",customPlan );
  //const currentCustomPlan = customPlan?.currentPlan;
  const { user, fiftyPercentOff } = userData;
  console.log("user-->", user);

  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [plan, setPlan] = useState<any>(selectedPlanData[0]);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const handleRadioChange = (selectedPlanId: any) =>
    setPlan(selectedPlanData[0]);
  const validateEmail = (email: string): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  useEffect(() => {
    setPlan(selectedPlanData[0]);
  }, [selectedPlanData]);

  const [referralCode, setReferralCode] = useState("");
  const [isVerified, setIsverified] = useState(false);
  const [isFullFree, setisFullFree] = useState<any>(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const { colorMode } = useColorMode();
  const isSpecialCountry = useSelector(
    (state: any) => state.planStore.isSpecialCountry?.data
  );
  const isDarkMode = colorMode === "dark";

  const styleInput =
    "w-full h-6 border-none !bg-transparent base1 outline-none placeholder:!text-[#B5B8BA] !text-black dark:!text-white";
  const handleCard = async (event: React.FormEvent) => {
    try {
      event.preventDefault();
      setLoading(true);
      if (!validateEmail(email)) {
        setEmailError(lt("invalid_emailFormat"));
        setLoading(false);
        return;
      }
      // @ts-ignore
      const cardElement = elements.getElement(CardNumberElement);

      // @ts-ignore
      const { error, paymentMethod } = await stripe?.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: email,
          name: user?.data?.userName
            ? user?.data?.userName
            : user.userName
            ? user.userName
            : "mobile user",
        },
        metadata: {
          userId: user?.data?.id,
        },
      });

      if (error) {
        toast(
          <Notify>
            <div className="ml-3 h6 sm:text-sm">
              {error.message ?? lt("something_wrong")}
            </div>
          </Notify>
        );
        console.log(error);
        setLoading(false);
      } else {
        console.log(paymentMethod, "Payment method");

        await upgradePlan(paymentMethod.id);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const upgradePlan = async (payId: any) => {
    // if (currentPlanPrice === 0 && plan?.title !== "Pay As You Go") {
    //   console.log("in if");
    planService
      .oneTimePayment({
        addonValue,
        planId: plan?._id,
        payId: payId,
        isVerified: isVerified,
        referralCode: isVerified == true ? referralCode : null,
        mPlan: plan,
      })
      .then((user: any) => {
        console.log("in .then");

        console.log("user''''", user);

        const updatedUser = {
          ...userData.user.data, // Existing user data
          ...user.data?.data, // Updated user data from the backend, including payAsYouGo fields
        };
        setOrderPlaced(true);

        dispatch(setUserSubscription(updatedUser));
        sessionStorage.setItem("hasRefreshed", "false");
        toast(
          (t) => (
            <Notify iconCheck>
              <div className="h6 sm:text-sm">{lt("subscrip_purchase")}</div>
            </Notify>
          ),
          { duration: 3000 }
        );

        router.push({
          pathname: ROUTES.THANKS,
          query: { plan: plan?.title },
        });
        setLoading(false);
        setVisibleModal(false);
        // if (userData?.user?.data?.userName) {
        //   window.location.href = "/pricing";
        // } else {
        //   // window.open(`loki://lokichat.com?subscription=${user.data.data.subscription}`, '_blank')
        //   window.location.replace(
        //     `loki://lokichat.com?subscription=${user.data.data.subscription}`
        //   );
        // }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
        if (error.response?.data?.status === 401) {
          console.log("rror.response?.data", error.response?.data);

          // window.location.href = ROUTES.SIGNIN;
        } else {
          toast(
            <Notify>
              <div className="ml-3 h6 sm:text-sm">
                {error.response?.data?.message}
              </div>
            </Notify>
          );
        }
      });
    // } else if (plan?.title === "Pay As You Go") {
    //   console.log("in else if");

    //   planService
    //     .oneTimePayment({
    //       planId: plan?._id,
    //       payId: payId,
    //       isVerified: isVerified,
    //       referralCode: isVerified == true ? referralCode : null,
    //       mPlan: plan,
    //     })
    //     .then((user: any) => {
    //       console.log("in .then");

    //       console.log("user''''", user);

    //       const updatedUser = {
    //         ...userData.user.data, // Existing user data
    //         ...user.data?.data, // Updated user data from the backend, including payAsYouGo fields
    //       };
    //       setOrderPlaced(true);

    //       dispatch(setUserSubscription(updatedUser));
    //       sessionStorage.setItem("hasRefreshed", "false");
    //       toast(
    //         (t) => (
    //           <Notify iconCheck>
    //             <div className="h6 sm:text-sm">{lt("subscrip_purchase")}</div>
    //           </Notify>
    //         ),
    //         { duration: 3000 }
    //       );

    //       router.push({
    //         pathname: ROUTES.THANKS,
    //         query: { plan: plan?.title },
    //       });
    //       setLoading(false);
    //       // if (userData?.user?.data?.userName) {
    //       //   window.location.href = "/pricing";
    //       // } else {
    //       //   // window.open(`loki://lokichat.com?subscription=${user.data.data.subscription}`, '_blank')
    //       //   window.location.replace(
    //       //     `loki://lokichat.com?subscription=${user.data.data.subscription}`
    //       //   );
    //       // }
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //       setLoading(false);
    //       if (error.response?.data?.status === 401) {
    //         console.log("rror.response?.data", error.response?.data);

    //         // window.location.href = ROUTES.SIGNIN;
    //       } else {
    //         toast(
    //           <Notify>
    //             <div className="ml-3 h6 sm:text-sm">
    //               {error.response?.data?.message}
    //             </div>
    //           </Notify>
    //         );
    //       }
    //     });
    // }
    // else {
    //   console.log("in else");

    //   planService
    //     .updateSubscritionThroughCard({
    //       planId: plan?._id,
    //       payId: payId,
    //       isVerified: isVerified,
    //       referralCode: isVerified == true ? referralCode : null,
    //       mPlan: plan,
    //     })
    //     .then((user: any) => {
    //       const updatedUser = {
    //         ...userData.user.data,
    //         subscription: user.data.data.subscription,
    //         planData: plan,
    //         plan: plan?._id,
    //       };
    //       setOrderPlaced(true);

    //       dispatch(setUserSubscription(updatedUser));
    //       sessionStorage.setItem("hasRefreshed", "false");
    //       toast(
    //         (t) => (
    //           <Notify iconCheck>
    //             <div className="h6 sm:text-sm">{lt("subscrip_purchase")}</div>
    //           </Notify>
    //         ),
    //         { duration: 3000 }
    //       );

    //       router.push({
    //         pathname: ROUTES.THANKS,
    //         query: { plan: plan?.title },
    //       });
    //       setLoading(false);
    //       // if (userData?.user?.data?.userName) {
    //       //   window.location.href = "/pricing";
    //       // } else {
    //       //   // window.open(`loki://lokichat.com?subscription=${user.data.data.subscription}`, '_blank')
    //       //   window.location.replace(
    //       //     `loki://lokichat.com?subscription=${user.data.data.subscription}`
    //       //   );
    //       // }
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //       setLoading(false);
    //       if (error.response?.data?.status === 401) {
    //         console.log("rror.response?.data", error.response?.data);

    //         // window.location.href = ROUTES.SIGNIN;
    //       } else {
    //         toast(
    //           <Notify>
    //             <div className="ml-3 h6 sm:text-sm">
    //               {error.response?.data?.message}
    //             </div>
    //           </Notify>
    //         );
    //       }
    //     });
    // }
  };

  // const updateSubscritionThroughCard = async (payId: any) => {
  //   console.log("updateSubscritionThroughCard runing");

  // };
  //   useEffect(() => {
  //     if (customPlan?.title) {
  //       setPlan(customPlan);
  //     }
  //   }, [plan, customPlan]);

  const baseStyle = {
    color: isDarkMode ? "#FFF" : "#000", // default color
    "::placeholder": {
      color: "#B5B8BA",
    },
  };

  const style = {
    base: baseStyle,
  };
  useEffect(() => {
    if (user?.data?.email) {
      setEmail(user.data.email);
    }
  }, [user]);

  // console.log("Plan==========:::", plan?.price);
  const selectedPlanPrice = plan?.price;
  const currentPlanPrice = currentPlan?.price;
  // console.log("currentPlanPrice--->",currentPlanPrice);
  console.log(selectedPlanData, "add");
  const textWhite = "Text White";
  return (
    <>
      <form action="" onSubmit={handleCard}>
        <div className="flex items-center justify-end mb-3 base2"></div>
        {/* {customPlan?.title ? (
          <FormRadioGroup className="mb-6" name="tailoredSubscriptionData">
            <FormRadioGroup.Option
              value={customPlan}
              className="p-3.5 whitespace-nowrap 2xl:w-full bg-n-2 dark:bg-transparent border-2 border-n-2 dark:border-n-2 rounded-xl transition-colors ui-checked:border-primary-1 ui-checked:bg-transparent cursor-pointer tap-highlight-color  dark:border-transparent dark:ui-checked:border-primary-1"
            >
              <div className="flex mb-1">
                <div className="base2 text-black dark:text-white">
                  {t("pay")} {customPlan?.title}
                </div>
                <Icon
                  className="ml-auto fill-primary-1 opacity-0 transition-opacity ui-checked:opacity-100"
                  name="check-thin"
                />
              </div>
              <div className="flex items-center gap-4">
                {isSpecialCountry ? (
                  <div className="base1 font-semibold text-black dark:text-white">
                    {customPlan?.price * 5.74}(R$)
                  </div>
                ) : (
                  <div className="base1 font-semibold text-black dark:text-white">
                    {customPlan?.price}$
                  </div>
                )}

                <div className="ml-auto px-2 base2 bg-primary-2/15 rounded text-[#0C923C] whitespace-nowrap">
                  {t("save_money")} {customPlan?.save}%
                </div>
              </div>
            </FormRadioGroup.Option>
          </FormRadioGroup>
        ) : ( */}
        <RadioGroup
          title={selectedPlanData[0]?.type}
          save={selectedPlanData[0].save}
          items={selectedPlanData?.slice(0, 1)}
          value={plan}
          setValue={handleRadioChange}
          selectedPlanData={selectedPlanData[0]}
          textWhite={textWhite}
        />
        {/* )} */}
        <div className="mb-3 border border-n-3 rounded-xl">
          <div className="p-5">
            <div className="mb-3 base2 text-n-5 font-semibold dark:text-white">
              {lt("bill_email")}
            </div>
            <div className="relative">
              <Icon
                className="absolute top-0 left-0 pointer-events-none fill-n-4/50"
                name="email"
              />
              <input
                className={`${styleInput} autofill:!bg-transparent pl-11`}
                type="email"
                name="email"
                placeholder={lt("email_address")}
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                  setEmailError(""); // Clear email error when input changes
                }}
                required
              />
            </div>
            {emailError && (
              <div className="text-red-500 mt-2">{emailError}</div>
            )}
          </div>
          <div className="p-5 border-t border-n-3">
            <div className="mb-3 base2 text-n-5 font-semibold dark:text-white">
              {lt("card_detail")}
            </div>

            <div className="flex md:flex-wrap">
              <div className="relative grow md:w-full md:mb-4">
                <Icon
                  className="absolute -top-[3px] left-0 pointer-events-none fill-n-4/50"
                  name="credit-card"
                />
                <CardNumberElement
                  className={`${styleInput} pl-11 border card-element focus:!text-gree-500 Card`}
                  onChange={(e: any) => {
                    console.log("CardNumberElement", e.target, e);
                  }}
                  options={{ style }}
                />
              </div>
              <div className="shrink-0 w-20 mx-8 md:ml-0 md:mr-auto">
                <CardExpiryElement
                  className={`${styleInput} text-center md:text-left`}
                  options={{ style }}
                />
              </div>
              <div className="shrink-0 w-10">
                <CardCvcElement
                  className={`${styleInput} text-center`}
                  // required
                  options={{ style }}
                  onChange={(e: any) => {
                    console.log("CardCvcElement", e.target, e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <Foot plan={plan} loading={loading} handleCard={handleCard} /> */}
        <>
          <AdditionalFoot
            plan={plan}
            loading={loading}
            handleCard={handleCard}
            setReferralCode={setReferralCode}
            setIsverified={setIsverified}
            isVerified={isVerified}
            isFullFree={isFullFree}
            setisFullFree={setisFullFree}
          />
        </>
      </form>
      {orderPlaced && (
        <PurchaseTracking
          orderId={user?.data?.subscription}
          orderSubtotal={plan?.price - currentPlanPrice}
        />
      )}
    </>
  );
};

export default AdditionalForm;
