import React, { useState } from "react";
import Modal from "@/components/Modal";
import SuccessFullyDeletedModal from "../SuccessFullyDeletedModal";
import { useTranslation } from "react-i18next";

type ChangeSubscriptionModal = {
  visible: boolean;
  onClose: () => void;
  changeSubscription: any;
  loading: boolean;
};
const ChangeSubscriptionModal = ({
  visible,
  onClose,
  changeSubscription,
  loading,
}: ChangeSubscriptionModal) => {
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const { t } = useTranslation("auth");

  return (
    <>
      <Modal
        classWrap="max-w-[32.188rem]"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:top-5 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visible}
        onClose={onClose}
      >
        <div className="py-13 flex flex-col justify-center items-center m-auto ">
          <h1 className="h4 mb-4">
            {loading == true ? t("updating") : t("update_subscription")}
          </h1>
          <p className="text-center text-sm text-n-4 mb-11 max-w-xs">
            {t("update_your_subscription")}
          </p>
          <div className="flex items-center justify-end gap-4">
            <button
              disabled={loading}
              className="btn-blue !tracking-wide"
              onClick={changeSubscription}
            >
              {loading == true ? t("updating") : t("update_subscription")}
            </button>
            <button
              disabled={loading}
              className="btn-stroke-light !tracking-wide"
              onClick={onClose}
            >
              {t("cancel_btn")}
            </button>
          </div>
        </div>
      </Modal>
      {/* <SuccessFullyDeletedModal visible={visibleModal} onClose={() => {setVisibleModal(false);}}/> */}
    </>
  );
};
export default ChangeSubscriptionModal;
